//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'element-type-icon',
  props: {
    // mediaType, or 'collection
    productType: {
      type: String,
      // required: true,
      default: 'motionelements',
    },
    size: {
      type: String,
      default: null,
      validator: value => ['lg', 'xs', 'sm', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].indexOf(value) > -1,
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
    rotation: {
      type: [String, Number],
      default: null,
      validator: value => [90, 180, 270].indexOf(parseInt(value, 10)) > -1,
    },
    flip: {
      type: String,
      default: null,
      validator: value => ['horizontal', 'vertical', 'both'].indexOf(value) > -1,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    pulse: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
    pull: {
      type: String,
      default: null,
      validator: value => ['right', 'left'].indexOf(value) > -1,
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    swapOpacity: {
      type: Boolean,
      default: false,
    },
    transform: {
      type: [Object, String],
      default: '',
    },
    mask: {
      type: Array,
      default() {
        return [];
      },
    },
    symbol: {
      type: [Boolean, String],
      default: false,
    },
    // for mediaType icons, e.g. ae
    square: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    icon() {
      const type = this.productType.replace('-', '_');
      switch (type) {
        case 'video':
        case 'video_live':
        case 'animation':
        case 'video_vr':
          return 'video';
        case '3d':
        case '3d_model':
          return '3d-model';
        case 'ae':
        case 'ae_preset':
        case 'ae_template':
          return this.square === false ? 'after-effects' : 'after-effects-square';
        case 'motion_template':
        case 'motion':
        case 'fcp':
          return 'apple-motion';
        case 'fcpx':
          return 'fcp';
        case 'pr':
        case 'pr_template':
        case 'pr_preset':
          return 'premiere-pro';
        case 'mg_template':
          return 'mogrt';
        case 'resolve':
        case 'resolve_template':
        case 'resolve_macro':
          return 'davinci-resolve';
        case 'image':
        case 'photo':
        case 'vector':
        case 'photo_vr':
          return 'image';
        case 'gif':
          return 'images';
        case 'music':
        case 'sfx':
        case 'lottie':
        case 'collection':
          return type;
        case 'graphic': // for channels
        case 'graphic_template':
          return 'graphic-template';
        case 'ui': // for channels
        case 'ui_template':
          return 'ui-template';
        case 'presentation': // for channels
        case 'presentation_template':
          return 'presentation-template';
        case 'notion': // for channels
        case 'notion_template':
          return 'notion-template';
        case 'midjourney': // for channels
        case 'midjourney_prompt':
        case 'image_prompt':
          return 'prompt';
        default:
          return 'motionelements';
      }
    },
  },
};
